import { loginToken } from '@/service/loginService'
import { currentUser } from '@/service/userService'
import { useUserStore } from '@/store/modules/user'
import { Button, CellGroup, Field } from 'vant'
import { defineComponent, reactive } from 'vue'

export default defineComponent({
    name: 'MLogin',
    components: {
        [Field.name]: Field,
        [Button.name]: Button,
        [CellGroup.name]: CellGroup
    },
    setup () {
        const userInfo = reactive<LoginParams>({
            cms_code: '',
            mobile: '',
            validateCode: ''
        })
        const handlerLoginSubmit = async () => {
            const result = await loginToken({ mobile: userInfo.mobile, cms_code: userInfo.cms_code })
            if (result) {
                useUserStore().setToken(result.result.accessToken)
                // 获取用户信息
                const resultData = await currentUser()
                if (resultData) {
                    useUserStore().setUserInfo(resultData.result)
                }
                window.location.href = '/'
            }
        }
        return {
            userInfo,
            handlerLoginSubmit
        }
    }
})
